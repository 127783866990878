<template>
  <div>
    <div>
      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">Block settings</label>
      </div>
      <div>
        <label class="label">
          Change Width <b>{{ item.styles.quests.list.width }}%</b>
        </label>
        <div class="control">
          <div class="slider">
            <v-slider
                hide-details
                :max="100"
                :min="33"
                v-model="item.styles.quests.list.width"
            ></v-slider>
          </div>
        </div>
        <label class="label">
          Change Height <b>{{ item.styles.quests.list.height }}px</b>
        </label>
        <div class="control">
          <div class="slider">
            <v-slider
                hide-details
                :max="600"
                :min="30"
                v-model="item.styles.quests.list.height"
            ></v-slider>
          </div>
        </div>
      </div>
      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">General Settings</label>
      </div>
      <div>
        <label class="label">Name position</label>
        <v-select :items="['bottom','inside']" v-model="item.styles.quests.list.show_name" outlined dense hide-details
                  style="margin-bottom: 15px;max-width:250px;"></v-select>
      </div>
      <div style="display: flex;align-items: center;margin-bottom: 30px;">
        <label class="label" style="margin-bottom: 0;margin-right: 15px;">
          Change Font Color
        </label>
        <div class="control">
          <v-menu
              top
              nudge-left="16"
              nudge-bottom="98"
              :close-on-content-click="false"
              v-model="show_text_color"
          >
            <template v-slot:activator="{ on }">
              <div
                  class="custom__menu--color"
                  :style="`background-color:${item.styles.quests.list.color}`"
                  v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                    v-model="item.styles.quests.list.color"
                    flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div>
        <label class="label">
          Change Font Size <b>{{ item.styles.quests.list.fontSize }}px</b>
        </label>
        <div class="control">
          <div class="slider">
            <v-slider
                hide-details
                :max="40"
                :min="10"
                v-model="item.styles.quests.list.fontSize"
            ></v-slider>
          </div>
        </div>
      </div>
      <div>
        <label class="label">
          Change Font Weight <b>{{ parseInt(item.styles.quests.list.fontWeight, 10) / 10 }}%</b>
        </label>
        <div class="control">
          <v-slider
              hide-details
              :max="1000"
              :min="100"
              v-model="item.styles.quests.list.fontWeight"
              :step="100"
          ></v-slider>
        </div>
      </div>


      <div style="display: flex;justify-content: space-between;">
        <div>
          <label class="label">
            Change Font Style <b>{{ item.styles.quests.list.fontStyle }}</b>
          </label>
          <div class="control" style="margin-bottom: 15px;">
            <v-radio-group row v-model="item.styles.quests.list.fontStyle">
              <v-radio
                  v-for="(type,typeIndex) in fontStyleTypes"
                  :key="type.id"
                  :label="type.label"
                  :value="type.value"
                  :style="`margin-left:${typeIndex?'15px':0}`"
              >
              </v-radio>
            </v-radio-group>
          </div>
        </div>
        <div>
          <label class="label">
            Change Font Family
          </label>

          <v-select
              flat
              dense
              outlined
              hide-details
              placeholder="Select Font Family"
              :items="fontFamilyTypes"
              item-text="label"
              item-value="value"
              class="canvas__select"
              v-model="item.styles.quests.list.fontFamily"
              style="margin-bottom: 15px;max-width:250px;"
          >
            <template v-slot:selection="{ item }">
              <div :style="`font-family:'${item.value}',sans-serif`">
                {{ item.label }}
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div :style="`font-family:'${item.value}',sans-serif`">
                {{ item.label }}
              </div>
            </template>
          </v-select>
        </div>
      </div>

      <div class="control">
        <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
      </div>
      <div
          class="control"
          style="display:flex;align-items:center;margin-bottom:15px;"
      >
        <label class="label" style="margin:0 15px 0 0;">Show Image</label>
        <v-switch v-model="item.styles.quests.list.show_icon" hide-details>
        </v-switch>
      </div>
      <div v-if="item.styles.quests.list.show_icon">
        <label class="label"
        >Change Height <b>{{ item.styles.quests.list.img_height }}</b> px</label>
        <div class="slider">
          <v-slider
              hide-details
              :min="10"
              :max="600"
              v-model="item.styles.quests.list.img_height"
          ></v-slider>
        </div>
        <label class="label"
        >Change Width <b>{{ item.styles.quests.list.img_width }}</b> px</label>
        <div class="slider">
          <v-slider
              hide-details
              :min="10"
              :max="600"
              v-model="item.styles.quests.list.img_width"
          ></v-slider>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "Quests",
  props:['item'],
  data(){
    return{
      show_text_color: false,
      show_text_price_color: false,
      btnColorCart:false,
      btnBorderCart:false,
      btnBgCart:false,
      btnColorMore:false,
      btnBorderMore:false,
      btnBgMore:false,
      fontStyleTypes: [
        { id: 1, value: "normal", label: "Normal" },
        { id: 0, value: "italic", label: "Italic" },
      ],
      fontFamilyTypes: [
        { id: 0, value: "Arial", label: "Arial" },
        { id: 1, value: "SF UI Text", label: "SF UI Text" },
        { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
        { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
        { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
        { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
        { id: 6, value: "Geometria", label: "Geometria" },
        { id: 7, value: "DIN Pro", label: "DIN Pro" },
        { id: 8, value: "Circe", label: "Circe" },
        { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
        { id: 10, value: "Acrom", label: "Acrom" },
        { id: 11, value: "Inter", label: "Inter" },
      ],
      positionTypes: [
        { id: 0, valueHorizontal: "flex-start" },
        { id: 1, valueHorizontal: "center" },
        { id: 2, valueHorizontal: "flex-end" },
      ],
    }
  },
  mounted(){
    //
  }
}
</script>

<style scoped>

</style>